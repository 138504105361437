import "../../css/fonts.css"
import "../../css/normalize.css"
import "../../css/skeleton.css"

import { renderToString } from 'react-dom/server'

import Everything from "../../components/Everything"
import ArticleList from "../../components/ArticleList"
import IndexContent from "../../components/IndexContent"
import { useAppSelector } from "../../hooks"
import { selectCurrentUserId } from "../../renderer/currentUserSlice"
import { useIsMember, useCurrentMemberOrg } from "../../lib/Auth"
import { urlHomeTenant, undefaultTenant, urlWriting } from "../../lib/urlHelpers"
import Hint from "../../lib/Hint"

export { Page }

/*
async function onBeforeRenderPage(store, pageContext) {
  const results = await articleListsWaitToFinish(store, CuratedArticleListSid)
  if (results[0].isError) {
    return {
      error: results[0].error,
      success: false
    }
  } else {
    return {
      success: true
    }
  }
}
*/

function ArticleListContent() {
  const isMember = useIsMember()
  const currentMemberOrg = useCurrentMemberOrg()
  const writingUrl = urlWriting()

  if (isMember) {
    const replaceMap = {
      link_to_writing: () => renderToString(<a href={writingUrl}>My&nbsp;Articles</a>),
      school_name: () => currentMemberOrg.name, // TODO: migrate to org_name
      org_name: () => currentMemberOrg.name
    }
    return <ArticleList listId="@homepage" topComponent={<Hint className="hint-main" hintKey="homepage_intro" replaceMap={replaceMap} showCloseButton={true} />} />
  } else {
    const subdomain = currentMemberOrg && currentMemberOrg.subdomain
    if (subdomain) {
      // For now, this, but eventually let's have a full-fledged letterty.com central and branch out into en.letterty.com and such
      window.location.replace(urlHomeTenant(undefaultTenant(subdomain)))

      return null
    } else {
      return null // or maybe some nice Access Denied
    }
  }
}

function Page() {
  const currentUserId = useAppSelector(selectCurrentUserId)

  return (
    <Everything isLoadingComponent={currentUserId ? null : " "}>
      { currentUserId ? <ArticleListContent /> : <IndexContent /> }
    </Everything>
  )
}
