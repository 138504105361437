import { useGetReadMarksBySidQuery } from "../services/backendApi"
import { stringToHtmlString } from "../lib/ReactUtil"
import pluralize from 'pluralize'
import classNames from 'classnames'
import Byline from './Byline'
import SourceByline from './SourceByline'
import { urlArticle } from "../lib/urlHelpers"
import CloneMessage from "./CloneMessage"

function ContribsCount({ totalContribsCount, unreadContribsCount }) {
  let contribsCountContent = null

  if (totalContribsCount === 0) {
    contribsCountContent = <span className="suggest">Be the first to share your opinion</span>
  } else if (unreadContribsCount) {
    if (unreadContribsCount === totalContribsCount) {
      contribsCountContent = <span className="unread"><span className="unread-count">{totalContribsCount}</span> unread {pluralize('comment', totalContribsCount)}</span>
    } else {
      contribsCountContent = <span className="unread"><span className="unread-count">{unreadContribsCount}</span>/{pluralize('comment', totalContribsCount, true)}</span>
    }
  } else {
    contribsCountContent = pluralize('comment', totalContribsCount, true)
  }

  return(
    <div className="counts">
      { contribsCountContent }
    </div>
  )
}

function ViewCount({ totalViewCount }) {
  if (totalViewCount) {
    return(
      <div className="views">
        Views: { totalViewCount }
      </div>
    )
  } else {
    return null
  }
}

function ArticleFromList({ title, lead, sid, cloneMessage, cloner, clonedAt, currentUserId, createdAt, creator, contribsByUserCounts }) {
  const readMarksResult = useGetReadMarksBySidQuery({ sid }, { skip: !currentUserId }) // TODO: just one total query for a bunch of sids
  const link = urlArticle(sid)

  let totalContribsCount = 0
  let myContribsCount = 0

  Object.keys(contribsByUserCounts).forEach((userId) => {
    const count = contribsByUserCounts[userId]

    totalContribsCount += count
    if (currentUserId && (Number(userId) === currentUserId)) {
      myContribsCount += count
    }
  })

  const othersContribsCount = totalContribsCount - myContribsCount
  const readMarksCount = readMarksResult.isLoading ?
    othersContribsCount :
    (readMarksResult.isSuccess ? (readMarksResult.data.root_marks[0] || 0) : 0)
  const totalViewCount = readMarksResult.isSuccess ? readMarksResult.data.view_count : null
  const unreadContribsCount = othersContribsCount - readMarksCount
  const isAuthorsNote = cloneMessage && (!creator.id || (creator.id  === cloner.id))

  return(
    <li>
      <a href={link}>
        <h2 className={classNames("title", { unread: !!unreadContribsCount })}>{stringToHtmlString(title)}</h2>
        <div className="lead">{stringToHtmlString(lead)}</div>
      </a>
      <Byline createdAt={clonedAt || createdAt} creator={cloner || creator} isLinked={true} by={cloner ? 'Posted by ' : null} />
      { cloneMessage && <CloneMessage message={cloneMessage} cloner={cloner} isAuthorsNote={isAuthorsNote} isLinked={true} /> }
      { false && cloner && <SourceByline sourceUrl={creator.link} sourceAuthor={creator.name} sourceAuthorImage={creator.image} createdAt={createdAt} isLinked={true} /> }
      <div className="article-info">
        <a href={link}><ContribsCount totalContribsCount={totalContribsCount} unreadContribsCount={unreadContribsCount} /></a>
        <ViewCount totalViewCount={totalViewCount} />
      </div>
      <hr/>
    </li>
  )
}

export default ArticleFromList
